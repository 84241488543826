import React from "react";
import PropTypes from "prop-types";
import styles from "./separator.module.scss";

const Separator = props => {
  const { isColor } = props;
  return <hr className={`${styles.separator} ${isColor && styles.color}`} />;
};

Separator.propTypes = {
  isColor: PropTypes.bool
};

Separator.defaultProps = {
  isColor: false
};

export default Separator;
