import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Link from "./link";
import styles from "./desktop-header.module.scss";

const DesktopHeader = ({ data }) => {
  return (
    <header className={styles.desktopHeader}>
      <Link className={styles.logo} to="/">
        <Img fixed={data.file.childImageSharp.fixed} />
      </Link>
      <nav className={styles.nav}>
        <ul className={styles.menu}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className={styles.menuItem}>
            <button className={styles.submenuButton} type="button">
              About
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#222222"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </button>
            <div className={styles.submenuHoverArea}>
              <ul className={styles.submenu}>
                <li>
                  <Link to="/about/philosophy">Philosophy</Link>
                </li>
                <li>
                  <Link to="/about/team">Team</Link>
                </li>
              </ul>
            </div>
          </li>
          <li className={styles.menuItem}>
            <button className={styles.submenuButton} type="button">
              Services
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#222222"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M6 9l6 6 6-6" />
              </svg>
            </button>
            <div className={styles.submenuHoverArea}>
              <ul className={styles.submenu}>
                <li>
                  <Link to="/services/individual-consultations">
                    Individual Consultations
                  </Link>
                </li>
                <li>
                  <Link to="/services/diabetic-education-consultations">
                    Diabetic Education Consultations
                  </Link>
                </li>
                <li>
                  <Link to="/services/fees">Fees</Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

DesktopHeader.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired
};

export default DesktopHeader;
