import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import MobileHeader from "./mobile-header";
import DesktopHeader from "./desktop-header";

const Header = ({ data, contactDetails }) => {
  const breakpoint = 768;
  const [isMobile, setIsMobile] = useState(
    typeof window !== "undefined" && window.innerWidth <= breakpoint
  );

  useEffect(() => {
    const handleIsMobileResize = () => {
      setIsMobile(window.innerWidth <= breakpoint);
    };

    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleIsMobileResize);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleIsMobileResize);
      }
    };
  });

  return isMobile ? (
    <MobileHeader data={data} contactDetails={contactDetails} />
  ) : (
    <DesktopHeader data={data} />
  );
};

Header.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  contactDetails: PropTypes.objectOf(PropTypes.string).isRequired
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "nourish-me-happy-logo-colour.png" }) {
          childImageSharp {
            fixed(height: 144, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);
