import React from "react";
import PropTypes from "prop-types";
import Separator from "./separator";
import styles from "./footer.module.scss";
import instagramIcon from "../img/instagram.png";
import facebookIcon from "../img/facebook.png";
import linkedinIcon from "../img/linkedin.png";

const Footer = props => {
  const { contactDetails, locations } = props;

  return (
    <footer className={styles.footer}>
      <Separator isColor />
      <div className={styles.container}>
        <div className={styles.details}>
          <h4 className={styles.heading}>Follow Us</h4>
          <a className={styles.socialMediaLink} href={contactDetails.instagram}>
            <img
              className={styles.socialMediaIcon}
              src={instagramIcon}
              alt="instagram icon"
            />
          </a>
          <a className={styles.socialMediaLink} href={contactDetails.facebook}>
            <img
              className={styles.socialMediaIcon}
              src={facebookIcon}
              alt="facebook icon"
            />
          </a>
          <a className={styles.socialMediaLink} href={contactDetails.linkedin}>
            <img
              className={styles.socialMediaIcon}
              src={linkedinIcon}
              alt="linkedin icon"
            />
          </a>
        </div>
        <div className={styles.details}>
          <h4 className={styles.heading}>Contact</h4>
          <address className={styles.address}>
            <a href={`mailto:${contactDetails.email}`}>
              {contactDetails.email}
            </a>
            <a href={`tel:${contactDetails.phone}`}>{contactDetails.phone}</a>
          </address>
        </div>
        <div className={styles.details}>
          <h4 className={styles.heading}>Locations</h4>
          {locations.map(location => (
            <address key={location.name} className={styles.address}>
              <h6 className={styles.locationName}>{location.name}</h6>
              <p className={styles.locationAddress}>{location.address}</p>
            </address>
          ))}
        </div>
      </div>
      <p className={styles.copyright}>© 2018. Nourish Me Happy</p>
    </footer>
  );
};

Footer.propTypes = {
  contactDetails: PropTypes.objectOf(PropTypes.string).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Footer;
