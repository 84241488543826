import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import Link from "./link";
import styles from "./mobile-header.module.scss";
import instagramIcon from "../img/instagram.png";
import facebookIcon from "../img/facebook.png";
import linkedinIcon from "../img/linkedin.png";

const SubMenu = props => {
  const { heading, items, isMainOpen } = props;
  const [isOpen, setIsOpen] = useState(isMainOpen && false);
  const toggleScroll = () =>
    document.querySelector("body").classList.toggle("no-scroll", !isOpen);
  useEffect(() => {
    if (!isMainOpen) {
      setIsOpen(false);
    }
  });
  return (
    <li
      className={`${styles.navItem} ${styles.submenu} ${
        isOpen ? styles.submenuOpen : ""
      }`}
    >
      <button
        className={styles.submenuHeading}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={styles.submenuHeadingText}>{heading}</span>
        <span className={styles.submenuIndicator}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#222222"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M6 9l6 6 6-6" />
          </svg>
        </span>
      </button>
      <ul className={styles.submenuContent}>
        {items.map(item => (
          <li key={item.name} className={styles.navItem}>
            <Link onClick={toggleScroll} className={styles.link} to={item.path}>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

SubMenu.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMainOpen: PropTypes.bool.isRequired
};

const MobileHeader = ({ data, contactDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleScroll = () =>
    document.querySelector("body").classList.toggle("no-scroll", !isOpen);

  return (
    <header className={`${styles.mobileHeader} ${isOpen ? styles.open : ""}`}>
      <button
        className={styles.menuButton}
        onClick={() => {
          setIsOpen(!isOpen);
          document.querySelector("body").classList.toggle("no-scroll", !isOpen);
        }}
        type="button"
      >
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
        <div className={styles.menuDot} />
      </button>
      <Link className={styles.logo} to="/">
        <Img
          style={{ height: "72px", width: "72px" }}
          fixed={data.file.childImageSharp.fixed}
        />
      </Link>
      <nav className={styles.nav}>
        <div className={styles.navContent}>
          <ul className={styles.navItems}>
            <li className={styles.navItem}>
              <Link onClick={toggleScroll} className={styles.link} to="/">
                Home
              </Link>
            </li>
            <SubMenu
              isMainOpen={isOpen}
              heading="About"
              items={[
                { path: "/about/philosophy", name: "Philosophy" },
                { path: "/about/team", name: "Team" }
              ]}
            />
            <SubMenu
              isMainOpen={isOpen}
              heading="Services"
              items={[
                {
                  path: "/services/individual-consultations",
                  name: "Individual Consultations"
                },
                {
                  path: "/services/diabetic-education-consultations",
                  name: "Diabetic Education Consultations"
                },
                { path: "/services/fees", name: "Fees" }
              ]}
            />
            <li className={styles.navItem}>
              <Link onClick={toggleScroll} className={styles.link} to="/faq">
                FAQ
              </Link>
            </li>
            <li className={styles.navItem}>
              <Link
                onClick={toggleScroll}
                className={styles.link}
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
          <div className={styles.socialMediaContainer}>
            <a
              className={styles.socialMediaLink}
              href={contactDetails.instagram}
            >
              <img
                className={styles.socialMediaIcon}
                src={instagramIcon}
                alt="instagram icon"
              />
            </a>
            <a
              className={styles.socialMediaLink}
              href={contactDetails.facebook}
            >
              <img
                className={styles.socialMediaIcon}
                src={facebookIcon}
                alt="facebook icon"
              />
            </a>
            <a
              className={styles.socialMediaLink}
              href={contactDetails.linkedin}
            >
              <img
                className={styles.socialMediaIcon}
                src={linkedinIcon}
                alt="linkedin icon"
              />
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

MobileHeader.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  contactDetails: PropTypes.objectOf(PropTypes.string).isRequired
};

export default MobileHeader;
