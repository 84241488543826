import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import Header from "./header";
// import MobileHeader from "./mobile-header";
import Footer from "./footer";
import "normalize.css";
import "./global.scss";

/* eslint-disable react/prop-types */
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
          frontmatter {
            contactDetails {
              email
              phone
              facebook
              twitter
              linkedIn
            }
            locationsContainer {
              locations {
                name
                address
                operationHours
              }
            }
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <Helmet>
          <html lang="en" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/favicon/site.webmanifest" />
        </Helmet>
        <Header
          contactDetails={data.markdownRemark.frontmatter.contactDetails}
        />
        <main>{children}</main>
        <Footer
          contactDetails={data.markdownRemark.frontmatter.contactDetails}
          locations={
            data.markdownRemark.frontmatter.locationsContainer.locations
          }
        />
      </React.Fragment>
    )}
  />
);

export default Layout;
